// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//* {
//    border: 1px solid red;
//}

body {
    background-color: white !important;
    font-family: 'Source Sans Pro', sans-serif;
}

.row {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.main-subtitle {
    //margin: 0;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: 600;
    letter-spacing: 2px;
    font-family: "Brandon Grotesque", sans-serif;
    font-size: 1.4em;
    color: #5E7C75;

    @media (max-width: 768px) {
        font-size: 1.1em;
    }
}

.main-title {
    text-transform: uppercase;
    font-size: 4em;
    font-family: "Requiem", serif;
    letter-spacing: 0.01em;
    margin: 0;
    margin-bottom: 15px;
    @media (max-width: 768px) {
        font-size: 2em;
    }
}

p {
    color: #777570;
    margin: 0 0 15px;
    letter-spacing: 0.2px;
}

.btn {
    border-style: solid;
    font-family: "Brandon Grotesque", sans-serif;
    border-width: 2px;
    border-radius: 30px;
    padding: 10px 30px;
    font-size: 0.8rem;
    font-weight: 700;
    text-align: center;
    //margin-top: 20px;
    text-transform: uppercase;
}

.btn-outline-primary {
    border-color: #A36833 !important;
    color: #777570;
    background-color: transparent;

    &:hover {
        color: white !important;
        background-color: #A36833;
    }
}

.btn-primary {
    border-color: #A36833 !important;
    color: white;
    background-color: #A36833 !important;

    &:hover {
        color: black !important;
        background-color: #A36833;
    }
}

.close {
    opacity: 1 !important;
    text-shadow: none;
    line-height: inherit;
    float: initial;

    &:hover {
        opacity: 1;
    }
}
//
//.img-mask {
//    height: 550px;
//    @media (max-width: 768px) {
//        height: 220px;
//    }
//}

.img {
    position: relative;
    overflow: hidden;
    backface-visibility: hidden;
    height: auto;
    max-width: 100%;
    object-fit: cover;
    border-radius: 50%;
}

#nabidka-vin {
    height: 80vh;
    background-image: url("../../resources/images/bg_sketch3.png");
    background-size: 1000px;
    background-repeat: no-repeat;
    background-position: bottom;

    p {
        color: black !important;
    }

    .btn {
        color: black;
    }
}

#panel-top {
    min-height: calc(100vh - 62.4px) !important;
    //height: 100vh;
    height: auto;
    background-image: url("../../resources/images/panel-top1.webp");
    //background-image: linear-gradient(to bottom, #ffffff 4%, transparent), url("../../resources/images/panel-top1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: -100px;

    @media (max-width: 996px) {
        background-position-y: 50px;
    }
    //backdrop-filter: contrast(1.1) saturate(1.4);
}

#bottled-wines {
    //height: calc(100vh - 92.8px) !important;
    //min-height: 100vh;
    height: auto;
    min-height: calc(100vh - 62.4px) !important;
    background-image: linear-gradient(to bottom, #ffffff 30%, transparent), url("../../resources/images/bg_test.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.text-black {
    color: black !important;
}

.wine-title {
    margin: 40px 0 20px;
    text-transform: uppercase;
    font-weight: 600;
    word-wrap: break-word;
    font-size: 1.3em;
    font-family: 'Brandon Grotesque';
    letter-spacing: 2px;
}

#offer-wines {
    //height: 80vh;
    background-image: url("../../resources/images/bg_sketch3.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
}

//Navbar
.navbar {
    width: 100%;
    background-color: black;

    .navbar-brand {
        img {
            transition: 0.3s;
        }
    }
}

.nav-item {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0 40px;
    @include media-breakpoint-down(lg) {
        padding: 0 !important;
    }
}

.nav-link {
    color: #d1ab2a !important;
}

.f-top {
    position: fixed;
    top: 0;
    z-index: 1020;

}

.title {
    font-weight: 600;
    font-size: 2.5rem;
    color: black;
}

.w-md-75 {
    @media (min-width: 768px) {
        width: 75% !important;
    }
}

.w-md-50 {
    @media (min-width: 768px) {
        width: 50% !important;
    }
}

#kontakt {
    background-color: black;
    color: white;
    @media (max-width: 996px) {
        margin-top: 5rem !important;
    }

    .title {
        //text-align: left;
        letter-spacing: 3px;
        font-size: 18px;
        color: #d1ab2a !important;
        //background: linear-gradient(to bottom, #cfc09f 22%,#634f2c 24%, #cfc09f 26%, #cfc09f 27%,#ffecb3 40%,#3a2c0f 78%);
        //-webkit-background-clip: text;
        //-webkit-text-fill-color: transparent;
        //font-family: 'Brandon Grotesque';
        text-transform: uppercase;
        font-weight: 600;
    }

    p, a {
        line-height: 2.2em;
        color: white;
    }

    .socials {
        display: inline-block;
        padding: 10px;
        border-radius: 50px;
        border: 1px solid #d1ab2a;
        color: #d1ab2a !important;
    }

    .fa.fa-circle-thin {
        color: #d1ab2a;
        font-weight: 100 !important;
        -webkit-text-stroke: 3px black;
    }
}

.scroll-down {
    border: 2px solid white;
    border-radius: 20px;
    bottom: 60px;
    height: 50px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 30px;

    &:before {
        animation: scrollDownAnimation 2s infinite;
        background-color: white;
        border-radius: 100%;
        content: '';
        height: 6px;
        left: 0;
        margin: 0 auto;
        position: absolute;
        right: 0;
        top: 10px;
        width: 6px;
    }

    @keyframes scrollDownAnimation {
        0% {
            opacity: 0;
            transform: translate(0, 0);
        }

        40% {
            opacity: 1;
        }

        80% {
            opacity: 0;
            transform: translate(0, 20px)
        }

        100% {
            opacity: 0
        }
    }
}

img {
    transition: 2s ease-in-out;
}

//Navbar: not visible on scroll down, visible on scroll up
.smart-scroll {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    width: 100%;
}

.scrolled-down {
    transform: translateY(-100%);
    transition: all 0.5s ease-in-out;
}

.scrolled-up {
    transform: translateY(0);
    transition: all 0.5s ease-in-out;
    background: black !important;

    .nav-link {
        color: #d1ab2a !important;
    }

    //box-shadow: 0 1px 10px #d3d3d3;
}
